import React, {useEffect, useState} from 'react'
import Button from '../components/Button'
import ScrollLink from '../components/ScrollLink'
import GradientContainer from '../components/GradientContainer'
import Heading from '../components/Heading'
import Highlight from '../components/Highlight'
import Layout from '../components/Layout'
import Courses from '../components/Courses'

import about from './../images/about.svg'
import faq from './../images/faq.svg'
import FAQ from '../components/FAQ'
import ContactForm from '../components/ContactForm'

export default function IndexPage() {
  const [years, setYears] = useState<number>(0)

  useEffect(() => {
    const startDate = new Date(2004, 4)
    const now = new Date()

    setYears(now.getFullYear() - startDate.getFullYear())
  })

  return (
    <Layout mainPage>
      <GradientContainer
        rtl
        className="z-40 min-h-[500px]"
      >
        <div className="flex flex-col items-center space-y-8 md:space-y-16">
          <div className="text-center">
            <Heading
              as="span"
              className={`
                text-2xl
                md:text-4xl md:leading-[60px]
                lg:text-5xl lg:leading-[80px]
              `}
              dotsContainerClassName="-top-16 md:-top-8"
            >
              Willkommen bei Marco's Fahrschule, <br />
              Deiner <Highlight as="h1" className="font-semibold">Fahrschule in Hünfeld</Highlight>
            </Heading>
          </div>
          <div className="z-10 mx-auto text-center max-w-[560px] text-base lg:text-lg">
            Wir bilden seit mehr als {years} Jahren in den Führerscheinklassen B, BE, L, AM, A1, A2 und A aus. Du möchtest deinen Führerschein machen? Informiere dich über unser Angebot oder nehme Kontakt mit uns auf.
          </div>
          <div className="flex flex-col items-center space-y-4">
            <ScrollLink href={`/unser-angebot`}>
              <Button aria-label="Zu den Ausbildungsklassen">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
                  <g fill="none" fillRule="evenodd" transform="translate(.714)">
                    <polygon points="0 0 24 0 24 24 0 24"/>
                    <path fill="#2C2E33" fillRule="nonzero" d="M18.92,5.01 C18.72,4.42 18.16,4 17.5,4 L6.5,4 C5.84,4 5.29,4.42 5.08,5.01 L3,11 L3,19 C3,19.55 3.45,20 4,20 L5,20 C5.55,20 6,19.55 6,19 L6,18 L18,18 L18,19 C18,19.55 18.45,20 19,20 L20,20 C20.55,20 21,19.55 21,19 L21,11 L18.92,5.01 Z M6.85,6 L17.14,6 L18.18,9 L5.81,9 L6.85,6 Z M19,16 L5,16 L5,11.34 L5.12,11 L18.89,11 L19,11.34 L19,16 Z"/>
                    <circle cx="7.5" cy="13.5" r="1.5" fill="#2C2E33" fillRule="nonzero"/>
                    <circle cx="16.5" cy="13.5" r="1.5" fill="#2C2E33" fillRule="nonzero"/>
                  </g>
                </svg>
                <span>Zu den Ausbildungsklassen</span>
              </Button>
            </ScrollLink>
            <div className="text-sm">
              oder direkt
              <ScrollLink href="/kontakt">
                <Highlight
                  className="mx-2 py-1"
                >
                  Kontakt aufnehmen
                </Highlight>
              </ScrollLink>
            </div>
          </div>
        </div>
      </GradientContainer>
      <GradientContainer
        id="ueber-uns"
        className="z-30 min-h-[500px]"
      >
        <div className="flex flex-col space-y-32">
          <div className="flex flex-col space-y-16 md:flex-row md:space-y-0 md:space-x-32">
            <div className="flex flex-1 items-center">
              <Heading
                as="span"
                className="text-3xl md:text-4xl lg:text-5xl font-semibold"
                dotsContainerClassName="-top-20"
              >
                <Highlight as="h2" className="!px-4">Über uns</Highlight>
              </Heading>
            </div>
            <div className="flex-1">
              <img src={about} alt="" width="1px" height="1px" className="w-full h-auto" />
            </div>
          </div>
          <div className="flex flex-1 flex-col space-y-8 lg:flex-row lg:space-y-0 lg:space-x-32">
            <div className="flex-1 relative z-1 flex flex-col space-y-8 text-base md:text-lg">
              <p>
                Nachdem ich seit vielen Jahren erfolgreich in Fulda als Fahrlehrer tätig war, habe ich als Hünfelder mich im April 2004 dazu entschlossen, eine neue, moderne Fahrschule hier in Hünfeld zu eröffnen.
              </p>
              <p>
                Hierbei ist mir die <Highlight>individuelle Betreuung aller Fahrschüler</Highlight> besonders wichtig. Dies ist unser Schlüssel zum Erfolg für eine gute Ausbildung.
              </p>
              <p>
                Fast drei Jahre lang war unsere Fahrschule am Fuldaer Berg .
                Verschiedene Probleme am Gebäude brachten uns dazu in die Kaiserstraße 9 umzuziehen (ehemals Fahrschule Lohr). Nach gründlichen Renovierungsarbeiten war am 02.01.2007 der Umzug abgeschlossen und wir gingen wieder frisch an’s Werk...
              </p>
              <p>
                Mittlerweile besteht unsere Fahrschule schon <Highlight>seit mehr als {years} Jahren.</Highlight>
              </p>
            </div>
            <div className="flex-1 relative z-1 flex flex-col space-y-8 text-base md:text-lg">
              <p>
                Als begeisterter Motorradfahrer habe ich mich schon länger auf die Ausbildung in den Zweiradklassen spezialisiert, weshalb ich euch auch so oft es geht mit dem Motorrad begleiten werde. Aus diesem Grund haben wir auch seit langem unser Angebot um <Highlight>Sicherheitstrainings für Motorradfahrer</Highlight> erweitert!
              </p>
              <p>
                <Highlight>Mir zur Seite steht Simone</Highlight>, die mir den “Verwaltungskram” abnimmt. Sie kümmert sich auch um eure Führerscheinanträge und die Anmeldung der Prüfungen.
              </p>
              <p>
                Ohne Simone läuft hier gar nichts!
              </p>
              <p>
                Sie begleitet euch dann auch zu eurer Theorieprüfung nach Fulda.
              </p>
              <p>
                Theoretische Prüfungen bieten wir wöchentlich an!
              </p>
            </div>
          </div>
        </div>
      </GradientContainer>
      <GradientContainer
        rtl
        className="z-20 min-h-[500px]"
        id="unser-angebot"
      >
        <div className="flex flex-col space-y-16 md:space-y-32">
          <div className="flex flex-col space-y-16 justify-center items-center">
            <Heading
              as="span"
              className="text-3xl lg:text-5xl font-semibold"
              dotsContainerClassName="-top-20"
            >
              <Highlight as="h2" className="!px-4">Unser Angebot</Highlight>
            </Heading>
            <p
              className="relative z-1 text-center max-w-[500px] text-base md:text-lg"
            >
              Neben der Führerscheinausbildung bieten wir dir außerdem Zusatzangebote wie Sicherheitstrainings an. Du hast eine Frage?{' '}
              <ScrollLink href="/kontakt">
                <span className="underline hover:opacity-50 transition-all">
                  Rufe uns an oder hinterlasse uns eine Nachricht!
                </span>
              </ScrollLink>
            </p>
          </div>
          <div className="flex flex-col space-y-16">
            <h2 className="text-3xl lg:text-4xl">Ausbildungsklassen</h2>
            <Courses />
          </div>
          <div className="flex flex-col space-y-16 w-full lg:w-2/3 2xl:w-1/2">
            <h2 className="text-3xl lg:text-4xl">Weitere Angebote</h2>
            <span className="text-lg">Ausser der klassischen Führerscheinausbildung bieten wir folgende Zusatzangebote:</span>
            <ul className="offers-list text-lg md:text-xl">
              <li>
                <Highlight as="span">Kurse für Führerscheininhaber</Highlight>, die längere Zeit kein Fahrzeug mehr bewegt haben,
                um Ihnen das Fahren und den Strassenverkehr wieder näher zu bringen.
                Besonders interessant auch für Wiedereinsteiger, die längere Zeit nicht
                Motorrad gefahren sind.
              </li>
              <li>
                <Highlight as="span">Umweltschonend Autofahren:</Highlight> Wir tragen so unseren Teil zum Thema Umweltschutz
                bei und müssen zudem noch weniger Kraftstoff tanken (und bezahlen)!
                Dank der Verbrauchsanzeige im Fahrzeug haben wir eine direkte Kontrolle
                und schon bald ein Erfolgserlebnis.
              </li>
              <li>
                Wir bereiten Sie auf die <Highlight as="span">Fahrverhaltensbeobachtung im Rahmen einer MPU</Highlight> vor
                und begleiten Sie dabei.
              </li>
              <li>
                <Highlight as="span">Sicherheitstrainings</Highlight> für Motorradfahrer.
              </li>
            </ul>
          </div>
        </div>
      </GradientContainer>
      <GradientContainer
        className="z-10 min-h-[500px]"
        id="faq"
      >
        <div className="flex flex-col space-y-16 md:space-y-32">
          <div className="flex flex-col text-center space-y-16 justify-center items-center">
            <Heading
              as="span"
              className="text-3xl lg:text-5xl font-semibold"
              dotsContainerClassName="-top-20"
            >
              <Highlight as="h2" className="!px-4 leading-[40px] md:leading-[60px]">Häufig gestellte Fragen</Highlight>
            </Heading>
          </div>
          <div className="flex flex-col space-y-16 md:flex-row md:space-y-0 md:space-x-32 md:items-start">
            <div className="flex flex-1">
              <img src={faq} alt="" width="1px" height="1px" className="w-full h-auto" />
            </div>
            <div className="flex-[2]">
              <FAQ />
            </div>
          </div>
        </div>
      </GradientContainer>
      <GradientContainer
        rtl
        className="z-0 min-h-[500px]"
        id="kontakt"
      >
        <div className="flex flex-col space-y-16 md:space-y-32">
          <div className="flex flex-col md:text-center space-y-16 md:justify-center md:items-center">
            <Heading
              as="span"
              className="text-3xl lg:text-5xl font-semibold"
              dotsContainerClassName="-top-20"
            >
              <Highlight as="h2" className="!px-4 leading-[40px] md:leading-[60px]">Kontakt</Highlight>
            </Heading>
          </div>
          <div className="flex flex-col space-y-16 md:flex-row md:space-y-0 md:space-x-32 md:items-start">
            <div className="flex flex-1">
              <ContactForm />
            </div>
            <div className="flex-1 flex flex-col space-y-16">
              <h2 className="text-3xl lg:text-4xl">Anschrift</h2>
              <div>
                <address className="mb-4 not-italic">
                  Marco &amp; Simone Waider<br />
                  Kaiserstraße 9<br />
                  36088 Hünfeld
                </address>
                <div className="mb-4 flex space-x-2">
                  <span>Telefon:</span>
                  <div>
                    06652 - 74 77 77<br />
                    0172 - 68 07 184
                  </div>
                </div>
                <p>
                  E-Mail: Marco@marcos-fahrschule.de
                </p>
              </div>
              <h2 className="text-3xl lg:text-4xl">Öffnungszeiten</h2>
              <div className="flex flex-col space-y-2">
                <div>
                  <Highlight>Bürozeiten:</Highlight> Mo bis Fr, 14:00 bis 18:30 Uhr
                </div>
                <div>
                  <Highlight>Unterricht:</Highlight> Mo + Mi von 18:30 bis 20:00 Uhr
                </div>
              </div>
            </div>
          </div>
        </div>
      </GradientContainer>
    </Layout>
  )
}
